export const getClearUrl = (obj: string, type: string, slug: string) => {
  let url = `${process.env.REACT_APP_SLUG_BASE_URL}/moskva`;

  if (obj === 'event') {
    type === 'Кино' ? (url += '/movie/') : (url += '/event/');
  }

  if (obj === 'place') {
    type === 'Кинотеатр' ? (url += '/cinema/') : (url += '/place/');
  }

  if (obj === 'person') {
    url += '/person/';
  }

  return (url += slug);
};
